import React, { useContext } from "react";
import MetisMenu from "react-metismenu";
import Cookies from "js-cookie";
import { BTP } from "./NavItems";
import { ProfileContext } from "../../contexts/ProfileContext";

const Nav = () => {
  const { logout } = useContext(ProfileContext);
  const url = new URL(document.URL);
  let pathToCheck = url.hash;
  const role = Cookies.get("user_role");
  let menuAccess = BTP.adminMenu;
  switch (role) {
    case "admin":
      menuAccess = BTP.adminMenu;
      break;
    case "internalUser":
      menuAccess = BTP.internalUserMenu;
      break;
    case "adsManager":
      menuAccess = BTP.adManagerMenu;
      break;
    case "contentManager":
      menuAccess = BTP.contentManagerMenu;
      break;
    default:
      menuAccess = [];
      break;
  }
  const existsInArray = menuAccess.some((item) => {
    if (item.routes) {
      pathToCheck = pathToCheck.replace("#/", "");
      const mainMenuExists = item.routes.some(
        (route) => pathToCheck.indexOf(route) === 0,
      );
      if (mainMenuExists) {
        return true;
      }
    }
    return false;
  });
  if (!existsInArray) {
    logout();
    Cookies.remove("auth_token");
    Cookies.remove("user_role");
  }

  return (
    <>
      {menuAccess.map((menu, i) => (
        <span key={"menui" + i + 1}>
          <h5 className="app-sidebar__heading">{menu.item.name}</h5>
          <MetisMenu
            content={menu.mainMenu}
            activeLinkFromLocation
            className="vertical-nav-menu text-secondary ps-0"
            iconNamePrefix=""
            classNameStateIcon="pe-7s-angle-down"
          />
        </span>
      ))}
    </>
  );
};
export default Nav;
