import React, { Fragment } from "react";

class AppFooter extends React.Component {
  render() {
    return (
      <Fragment>
        <div className="app-footer">
          <div className="app-footer__inner d-flex justify-content-center">
            Copyright © 2014-2024 Mykids Ventures Private Limited. 1.0.5
          </div>
        </div>
      </Fragment>
    );
  }
}

export default AppFooter;
