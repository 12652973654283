import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import "react-toastify/dist/ReactToastify.css";
import { Card, Button, Form, InputGroup, Container } from "react-bootstrap";
import Login from "../../services/Login";
import GoogleSignInBtn from "../../Components/GoogleSignIn";
import Cookies from "js-cookie";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { ProfileContext } from "../../contexts/ProfileContext";
import ForgotPassword from "../ForgotPassword/ForgotPassword";

export default function LoginPage() {
  const { login } = useContext(ProfileContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [passwordType, setPasswordType] = useState("password");
  const [openForgetModal, setOpenForgetModal] = useState(false);

  const navigate = useHistory();

  const handleLogin = async (e) => {
    e.preventDefault();
    const data = await Login.userLogin({
      email,
      password,
    });
    if (data.status === 200) {
      const path = Cookies.get("path");
      const accessToken = Cookies.get("auth_token");
      await login(accessToken);
      if (path !== "") {
        navigate.push(path);
      } else setError("You are not authorized");
    }
  };

  const handleShowPassword = () => {
    setPasswordType("text");
    setShowPassword(true);
  };

  const handleHidePassword = () => {
    setPasswordType("password");
    setShowPassword(false);
  };

  const handleClikForgetPassword = () => {
    setOpenForgetModal(true);
  };

  return (
    <>
      {openForgetModal && (
        <ForgotPassword
          openForgetModal={openForgetModal}
          setOpenForgetModal={setOpenForgetModal}
        />
      )}
      <Container className="d-flex align-items-center justify-content-center h-100">
        <Card id="login-card" className="p-5">
          <img
            height="auto"
            alt=""
            src={process.env.PUBLIC_URL + "/images/being-the-parent.webp"}
          />
          <Form className="mt-4">
            <Form.Group>
              <Form.Label for="exampleEmail">Email</Form.Label>
              <Form.Control
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                name="email"
                id="exampleEmail"
                placeholder="Enter your email"
              />
            </Form.Group>
            <Form.Group className="mt-3">
              <Form.Label for="examplePassword">Password</Form.Label>
              <Form.Group className="">
                <InputGroup>
                  <Form.Control
                    type={passwordType}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    name="password"
                    id="examplePassword"
                    placeholder="Enter your password"
                    className="password-input"
                  />
                  <InputGroup.Text className="bg-white">
                    <span
                      className={`bg-none text-secondary password-btn ${
                        showPassword ? "active" : ""
                      }`}
                      onClick={
                        showPassword ? handleHidePassword : handleShowPassword
                      }
                    >
                      <FontAwesomeIcon
                        icon={showPassword ? faEye : faEyeSlash}
                      />
                    </span>
                  </InputGroup.Text>
                </InputGroup>
              </Form.Group>
            </Form.Group>
            {error && <p className="validation-error mt-2 mb-0">{error}</p>}
            <Button
              type="submit"
              className="btn login-btn mt-3 w-100"
              onClick={handleLogin}
            >
              Submit
            </Button>
          </Form>

          <button
            className="forget-pass mt-2"
            onClick={handleClikForgetPassword}
          >
            Forgot Password
          </button>

          <h6 className="slashed-text">
            <em>OR</em>
          </h6>
          <GoogleOAuthProvider clientId="285015230290-8hf0spbdpceq31bjbgc80bpg3e97i1qd.apps.googleusercontent.com">
            <GoogleSignInBtn />
          </GoogleOAuthProvider>
        </Card>
      </Container>
    </>
  );
}
