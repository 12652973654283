import React from "react";
import cx from "classnames";
import ResizeDetector from "react-resize-detector";

import AppMain from "../../Layout/AppMain";

const defaults = {
  backgroundColor: "bg-royal sidebar-text-light",
  headerBackgroundColor: "bg-strong-bliss header-text-light",
  enableMobileMenuSmall: "",
  enableBackgroundImage: true,
  enableClosedSidebar: false,
  enableFixedHeader: true,
  enableHeaderShadow: true,
  enableSidebarShadow: true,
  enableFixedFooter: true,
  enableFixedSidebar: true,
  colorScheme: "white",
  backgroundImageOpacity: "opacity-06",
  enablePageTitleIcon: true,
  enablePageTitleSubheading: true,
  enablePageTabsAlt: false,
};

class Main extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      closedSmallerSidebar: false,
      width: undefined,
    };
  }

  onResize = (width) => this.setState({ width });

  render() {
    const { width } = this.state;

    const {
      colorScheme,
      enableFixedHeader,
      enableFixedSidebar,
      // EnableFixedFooter,
      enableClosedSidebar,
      closedSmallerSidebar,
      enableMobileMenu,
    } = defaults;
    return (
      <div
        className={cx(
          `app-container app-theme-${colorScheme}`,
          { "fixed-header": enableFixedHeader },
          { "fixed-sidebar": enableFixedSidebar || width < 1250 },
          { "closed-sidebar": enableClosedSidebar || width < 1250 },
          { "closed-sidebar-mobile": closedSmallerSidebar || width < 1250 },
          { "sidebar-mobile-open": enableMobileMenu },
        )}
      >
        <AppMain />
        <ResizeDetector handleWidth onResize={this.onResize} />
      </div>
    );
  }
}

export default Main;
