import React, { Fragment } from "react";
import { Slider } from "react-burgers";
import AppMobileMenu from "../AppMobileMenu";
import propTypes from "prop-types";

class HeaderLogo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      mobile: false,
      activeSecondaryMenuMobile: false,
      enableClosedSidebar: this.props.enableClosedSidebar,
    };
  }

  toggleEnableClosedSidebar = () => {
    this.setState((prevState) => ({
      enableClosedSidebar: !prevState.enableClosedSidebar,
    }));
  };

  render() {
    const { enableClosedSidebar } = this.state;

    return (
      <Fragment>
        <div className="app-header__logo">
          <div className="logo-src">
            <img
              height="30px"
              alt=""
              src={process.env.PUBLIC_URL + "/images/being-the-parent.webp"}
            />
          </div>

          <div className="header__pane ms-auto">
            <div onClick={this.toggleEnableClosedSidebar}>
              <Slider
                active={enableClosedSidebar}
                type="elastic"
                onClick={() => this.setState({ active: !this.state.active })}
              />
            </div>
          </div>
        </div>
        <AppMobileMenu />
      </Fragment>
    );
  }
}
HeaderLogo.propTypes = {
  enableClosedSidebar: propTypes.bool,
};

export default HeaderLogo;
