import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const errorHandling = {
  async handleErrors(response) {
    if (response.status === "401" || response.status === "403") {
      await toast.error(response.message, { toastId: "error" });
      window.location = "/";
    } else if (response.message) {
      await toast.error(response.message, { toastId: "error" });
    } else if (response.msg) {
      await toast.error(response.msg, { toastId: "error" });
    } else {
      await toast.error(response, { toastId: "error" });
      return;
    }
    return true;
  },
};

export default errorHandling;
