import React, { Component, Fragment } from "react";
import cx from "classnames";
import propTypes from "prop-types";
import { CSSTransition, TransitionGroup } from "react-transition-group";

import PerfectScrollbar from "react-perfect-scrollbar";
import Nav from "../AppNav/VerticalNavWrapper";
import HeaderLogo from "../AppLogo";

class AppSidebar extends Component {
  state = {};

  toggleMobileSidebar = () => {
    const { enableMobileMenu, setEnableMobileMenu } = this.props;
    setEnableMobileMenu(!enableMobileMenu);
  };

  render() {
    const {
      enableSidebarShadow,

      backgroundImageOpacity,
    } = this.props;
    return (
      <>
        <div
          className="sidebar-mobile-overlay"
          onClick={this.toggleMobileSidebar}
        />
        <TransitionGroup>
          <CSSTransition
            component="div"
            className={cx("app-sidebar", "bg-white", {
              "sidebar-shadow": enableSidebarShadow,
            })}
            appear
            timeout={1500}
            enter={false}
            exit={false}
          >
            <div>
              <HeaderLogo />
              <PerfectScrollbar>
                <div className="app-sidebar__inner">
                  <Nav />
                </div>
              </PerfectScrollbar>
              <div className={cx("app-sidebar-bg", backgroundImageOpacity)} />
            </div>
          </CSSTransition>
        </TransitionGroup>
      </>
    );
  }
}

AppSidebar.propTypes = {
  enableMobileMenu: propTypes.bool,
  setEnableMobileMenu: propTypes.func,
  backgroundColor: propTypes.string,
  enableBackgroundImage: propTypes.bool,
  enableSidebarShadow: propTypes.bool,
  backgroundImage: propTypes.string,
  backgroundImageOpacity: propTypes.string,
};

export default AppSidebar;
