import moment from "moment";

const checkStage = (data, stage) => {
  if (
    data.i_am === "2" &&
    data.due_date_parent !== "" &&
    data.due_date_parent !== null
  ) {
    if (moment(data.due_date_parent, "YYYY-MM-DD").isValid()) {
      return stage;
    }
  }
  if (
    (data.i_am === "3" || data.i_am === "5") &&
    data.no_of_children !== "" &&
    data.no_of_children !== null &&
    data.no_of_children !== 0
  ) {
    return stage;
  }
  if (
    data.i_am === "4" &&
    ((data.no_of_children !== "" &&
      data.no_of_children !== null &&
      data.no_of_children !== 0) ||
      (data.due_date_parent !== "" && data.due_date_parent !== null))
  ) {
    if (moment(data.due_date_parent, "YYYY-MM-DD").isValid()) {
      return stage;
    }
  }
  if (data.i_am === "1") {
    return stage;
  }
  return "L1";
};
const BTPFunctions = {
  setLevel(data) {
    if (data.user_status === 1) {
      if (
        data.email !== "" &&
        data.email !== null &&
        data.i_am !== "" &&
        data.i_am !== null &&
        data.pincode !== "" &&
        data.pincode !== null
      ) {
        return checkStage(data, "L3");
      }
      if (
        data.email !== "" &&
        data.email !== null &&
        data.i_am !== "" &&
        data.i_am !== null
      ) {
        return checkStage(data, "L2");
      }
      if (data.email !== "" || data.email !== null) {
        return "L1";
      }
    }
  },

  getStageName: (data) => {
    switch (data) {
      case "1":
        return "Planning";
      case "2":
        return "Pregnant";
      case "3":
        return "Parent";
      case "4":
        return "Parent and Pregnant";
      case "5":
        return "Parent and Planning";
      default:
        return "";
    }
  },
  getStages: () => [
    { value: 1, label: BTPFunctions.getStageName("1") },
    { value: 2, label: BTPFunctions.getStageName("2") },
    { value: 3, label: BTPFunctions.getStageName("3") },
    { value: 4, label: BTPFunctions.getStageName("4") },
    { value: 5, label: BTPFunctions.getStageName("5") },
  ],

  getUserAccess: (role) => {
    switch (role) {
      case "admin":
        return "/dashboards/total-data";
      case "internalUser":
        return "/user-management/users";
      case "adsManager":
        return "/dashboards/total-data";
      case "contentManager":
        return "/content/week-list";
      default:
        return "";
    }
  },
  getWeeks: (isAllWeeks) => {
    let weekData = Array.from({ length: 37 }, (_, index) => index + 4);
    let allWeeks = [];
    if (isAllWeeks) {
      allWeeks = ["All Weeks"];
    }
    weekData = [...allWeeks, ...weekData];
    return weekData;
  },
};

export { BTPFunctions };
