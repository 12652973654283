import Cookies from "js-cookie";

const checkIsAdmin = () => {
  const role = Cookies.get("user_role");
  return role === "admin";
};

const getUserRole = (role) => role;

export { checkIsAdmin, getUserRole };
