import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Forgot from "../../services/Forgot";
const ForgotPassword = (prop) => {
  const [formData, setFormData] = useState({});
  const [status, setStatus] = useState("");
  const [timer, setTimer] = useState(30);
  const [resendButtonDisabled, setResendButtonDisabled] = useState(false);
  const [showScreenChangePassword, setShowScreenChangePassword] =
    useState(false);
  const [passwordFormData, setPasswordFormData] = useState({});
  const [validated, setValidated] = useState(false);

  const handleClose = () => {
    prop.setOpenForgetModal(false);
  };

  const startTimer = () => {
    setResendButtonDisabled(true);
    setTimer(30);
    const interval = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer === 1) {
          clearInterval(interval);
          setResendButtonDisabled(false);
        }
        return prevTimer - 1;
      });
    }, 1000);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    } else {
      const data = {
        email: formData.email,
      };
      Forgot.forgetPassword(data)
        .then((res) => {
          if (res.status === "success") {
            setStatus(res.status);
            startTimer();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    setValidated(true);
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const [otpFormData, setOtpFormData] = useState({});
  const handleChangeOtp = (e) => {
    setOtpFormData({ ...otpFormData, [e.target.name]: e.target.value });
  };

  const handleSubmitOtp = (e) => {
    e.preventDefault();
    const data = {
      email: formData?.email,
      otp: otpFormData.otp,
    };
    Forgot.verifyOtp(data)
      .then((res) => {
        if (res.status === "success") {
          setShowScreenChangePassword(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleResend = (e) => {
    startTimer();
    e.preventDefault();
    const data = {
      email: formData.email,
    };
    Forgot.forgetPassword(data)
      .then(() => {
        // No specific action needed after resolving the promise
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChangePassword = (e) => {
    setPasswordFormData({
      ...passwordFormData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmitPassword = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    } else {
      const data = {
        email: formData.email,
        password: passwordFormData.password,
        otp: otpFormData.otp,
      };
      Forgot.resetPassword(data)
        .then((res) => {
          if (res.status === "success") {
            prop.setOpenForgetModal(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    setValidated(true);
  };

  return (
    <>
      <Modal
        className="forget-pass-modal"
        backdrop="static"
        show={prop.openForgetModal}
        onHide={handleClose}
        centered
      >
        <Modal.Header closeButton>
          {showScreenChangePassword === false && (
            <Modal.Title>Forgot Password</Modal.Title>
          )}
          {showScreenChangePassword === true && (
            <Modal.Title>Change Password</Modal.Title>
          )}
        </Modal.Header>
        {showScreenChangePassword === false && (
          <Modal.Body>
            {status !== "success" ? (
              <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Form.Group className="mb-3" controlId="validationCustom01">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    name="email"
                    required
                    type="email"
                    placeholder="example@email.com"
                    onChange={handleChange}
                    value={formData.email || ""}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter your email id
                  </Form.Control.Feedback>
                </Form.Group>
                <div className="d-flex justify-content-end mt-4">
                  <Button variant="outline-secondary" onClick={handleClose}>
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    variant="outline-primary"
                    className="ms-3"
                  >
                    Submit
                  </Button>
                </div>
              </Form>
            ) : (
              <>
                <Form onSubmit={handleSubmitOtp}>
                  <Form.Group className="mb-3">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      name="otp"
                      type="text"
                      placeholder="Enter your OTP"
                      value={formData?.email || ""}
                      disabled
                    />
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label>Otp</Form.Label>
                    <Form.Control
                      name="otp"
                      type="text"
                      placeholder="Enter your OTP"
                      value={otpFormData?.otp || ""}
                      onChange={handleChangeOtp}
                    />
                  </Form.Group>
                  <Button
                    className="btn-verify"
                    variant="outline-primary"
                    type="submit"
                  >
                    Verify
                  </Button>
                </Form>
                <Button
                  variant="outline-primary"
                  onClick={handleResend}
                  disabled={resendButtonDisabled}
                >
                  Resend {timer > 0 && `(${timer})`}
                </Button>
              </>
            )}
          </Modal.Body>
        )}

        {showScreenChangePassword === true && (
          <Modal.Body>
            <Form
              noValidate
              validated={validated}
              onSubmit={handleSubmitPassword}
            >
              <Form.Group className="mb-3">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  name="email"
                  type="email"
                  placeholder="Enter your OTP"
                  value={formData?.email || ""}
                  disabled
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  name="password"
                  type="text"
                  placeholder="Enter your new password"
                  required
                  value={passwordFormData.password}
                  onChange={handleChangePassword}
                  pattern="^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$"
                />

                <Form.Control.Feedback type="invalid">
                  Password must contain at least 8 characters, including one
                  uppercase letter, one lowercase letter, and one number
                </Form.Control.Feedback>
              </Form.Group>
              <Button variant="outline-primary" type="submit">
                Change Password
              </Button>
            </Form>
          </Modal.Body>
        )}
      </Modal>
    </>
  );
};

export default ForgotPassword;
