import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import FormGroup from "react-bootstrap/FormGroup";
import { Row, Col } from "react-bootstrap";
import Profile from "../../../services/Profile";
import { useQueryClient } from "@tanstack/react-query";
import propTypes from "prop-types";
import Select from "react-select";
import roles from "../../../config/roles.json";
import { checkIsAdmin } from "../../../Components/GetUserAccess";
import { BTPFunctions } from "../../../services/BTPFunctions";

function AddUserForm(props) {
  AddUserForm.propTypes = {
    onHide: propTypes.func,
  };
  const [password, setPassword] = useState("");
  const [fullName, setFullName] = useState("");
  const [rePassword, setRePassword] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [stage, setStage] = useState("");
  const [gender, setGender] = useState("");
  const [userRole, setUserRole] = useState({
    value: "internalUser",
    label: "Internal User",
  });
  const queryClient = useQueryClient();
  const genderArr = [
    { value: "Male", label: "Male" },
    { value: "Female", label: "Female" },
  ];
  const handleValidations = () => {
    if (password === rePassword && password !== "") {
      return true;
    }
  };

  const handleUserEmailChange = (event) => {
    setUserEmail(event.target.value);
  };

  const handleUserRoleChange = (selectedOption) => {
    setUserRole(selectedOption);
  };

  const handleSubmit = async () => {
    const check = handleValidations();
    if (check) {
      const response = await Profile.registerUser({
        full_name: fullName,
        email: userEmail,
        password,
        role: userRole.value,
        i_am: stage,
        gender,
      });
      if (response.status === 200) {
        queryClient.invalidateQueries("internalUserList");
        setFullName("");
        setPassword("");
        setRePassword("");
        setUserEmail("");
        props.onHide();
      }
    }
  };

  return (
    <Modal {...props} centered>
      <Modal.Body>
        <Modal.Title id="contained-modal-title-vcenter">Add User</Modal.Title>
        <Form>
          <FormGroup className="mt-3">
            <FloatingLabel for="fullName">Full Name</FloatingLabel>
            <Form.Control
              type="text"
              id="fullName"
              className="mt-1"
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
              required
            />
          </FormGroup>
          <FormGroup className="mt-3">
            <FloatingLabel for="userEmail">User Email</FloatingLabel>
            <Form.Control
              type="email"
              id="userEmail"
              className="mt-1"
              value={userEmail}
              onChange={handleUserEmailChange}
              required
            />
            <Form.Control.Feedback type="invalid">
              Please provide a valid email.
            </Form.Control.Feedback>
          </FormGroup>
          <Row>
            <Col>
              <Form.Group className="mt-3">
                <Form.Label for="type">Stages:</Form.Label>
                <Select
                  displayValue="stages"
                  onChange={(selectedList) => setStage(selectedList.value)}
                  options={BTPFunctions.getStages()}
                  placeholder=""
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mt-3">
                <Form.Label for="type">Gender:</Form.Label>
                <Select
                  displayValue="stages"
                  onChange={(selectedList) => setGender(selectedList.value)}
                  options={genderArr}
                  placeholder=""
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <FormGroup>
                <FloatingLabel for="password">Password</FloatingLabel>
                <Form.Control
                  type="password"
                  id="password"
                  className="mt-1"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please provide a valid password.
                </Form.Control.Feedback>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <FloatingLabel for="password">Re-enter Password</FloatingLabel>
                <Form.Control
                  type="password"
                  id="password"
                  className="mt-1"
                  value={rePassword}
                  onChange={(e) => setRePassword(e.target.value)}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please provide a valid password.
                </Form.Control.Feedback>
              </FormGroup>
            </Col>
          </Row>
          {checkIsAdmin() ? (
            <FormGroup className="mt-3">
              <FloatingLabel for="userRole">User Role</FloatingLabel>
              <Select
                isClearable
                options={roles}
                value={userRole}
                onChange={handleUserRoleChange}
                isSearchable={true}
                className="mt-1"
                placeholder="Select User Role"
              />
            </FormGroup>
          ) : (
            <></>
          )}
        </Form>
        <span className="d-flex justify-content-end mt-4">
          <Button variant="outline-secondary" onClick={props.onHide}>
            Cancel
          </Button>
          <Button
            variant="outline-primary"
            className="ms-3"
            onClick={handleSubmit}
          >
            Add
          </Button>
        </span>
      </Modal.Body>
    </Modal>
  );
}

export default AddUserForm;
