import axios from "axios";
import apiUrl from "../config/btpApi";
import { BTPAuth } from "./BTPAuth";
import errorHandling from "./ErrorHandling";
import Cookies from "js-cookie";
import { BTPFunctions } from "./BTPFunctions";

const Login = {
  userLogin: async (data) => {
    try {
      const response = await BTPAuth.fetchData(
        apiUrl.loginUrl,
        "post",
        { "client-platform": "web" },
        data,
      );
      Cookies.set("auth_token", response.data.token);

      Cookies.set("user_role", response.data.user.role);
      Cookies.set("user_id", response.data.user.sk_user_id);

      Cookies.set("path", BTPFunctions.getUserAccess(response.data.user.role));
      return response;
    } catch (err) {
      errorHandling.handleErrors(
        err,
        err.response && err.response.status ? err.response.status : "500",
      );
    }
  },

  loginSSO: async (data) => {
    try {
      const response = await axios.post(apiUrl.loginSSOUrl, data, {
        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY,
        },
      });
      Cookies.set("auth_token", response.data.token);

      Cookies.set("user_role", response.data.user.role);
      Cookies.set("user_id", response.data.user.sk_user_id);

      Cookies.set("path", BTPFunctions.getUserAccess(response.data.user.role));
      return response;
    } catch (err) {
      return err.response.data;
    }
  },
};

export default Login;
