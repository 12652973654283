import apiUrl from "../config/btpApi";
import { BTPAuth } from "./BTPAuth";
import errorHandling from "./ErrorHandling";

const Profile = {
  getUserDetails: async () => {
    try {
      const response = await BTPAuth.fetchData(apiUrl.profileUrl, "get");
      return response;
    } catch (err) {
      errorHandling.handleErrors(err, err.response.status);
    }
  },

  getOtherUserDetails: async (userId) => {
    try {
      const response = await BTPAuth.fetchData(
        apiUrl.otherUsersProfile,
        "post",
        {},
        { id: userId },
      );
      return response.data;
    } catch (err) {
      errorHandling.handleErrors(err, err.response.status);
    }
  },

  getUsers: async (page, queryData) => {
    let data = {};
    const query = { ...queryData, user_status: 1 };
    data = {
      table: "mst_user",
      clause: "AND",
      query,
      sortBy: {
        column: "created_date_time",
        order: "DESC",
      },
    };

    const queryParams = {
      page: page.page,
      limit: page.limit,
    };
    try {
      const response = await BTPAuth.fetchData(
        apiUrl.searchUrl +
          "?page=" +
          (queryParams.page + 1) +
          "&limit=" +
          queryParams.limit +
          "&total=" +
          true,
        "post",
        {},
        data,
      );
      return response.data;
    } catch (err) {
      errorHandling.handleErrors(err, err.response.status);
    }
  },

  getInternalUser: async (id) => {
    try {
      const response = await BTPAuth.fetchData(
        apiUrl.createInternalUserUrl + "?userId=" + id,
        "get",
      );
      return response.data;
    } catch (err) {
      errorHandling.handleErrors(err, err.response.status);
    }
  },

  getInpersonatelUser: async (id, type) => {
    try {
      const response = await BTPAuth.fetchData(
        apiUrl.impersonateUserUrl,
        "post",
        {},
        id,
        type,
      );
      return response.data;
    } catch (err) {
      errorHandling.handleErrors(
        err?.response?.data?.message || "User does not have exchange token",
      );
    }
  },

  getCreatedBy: async (id) => {
    try {
      const response = await BTPAuth.fetchData(
        apiUrl.createdByUserDetail + "?userId=" + id,
        "get",
      );
      return response;
    } catch (err) {
      // ErrorHandling.handleErrors(err, err.response.status);
    }
  },

  updateUser: async (data) => {
    //
    try {
      const response = await BTPAuth.fetchData(
        apiUrl.updateUserUrl,
        "patch",
        {},
        data,
      );
      return response;
    } catch (err) {
      errorHandling.handleErrors(err, err.response.status);
    }
  },

  updatePassword: async (password) => {
    try {
      const response = await BTPAuth.fetchData(
        apiUrl.updatePassword,
        "post",
        {},
        password,
      );
      return response;
    } catch (err) {
      errorHandling.handleErrors(err, err.response.status);
    }
  },

  deleteUser: async (id) => {
    try {
      const response = await BTPAuth.fetchData(
        apiUrl.deleteUserUrl,
        "post",
        {},
        id,
      );
      return response;
    } catch (err) {
      errorHandling.handleErrors(err, err.response.status);
    }
  },

  registerUser: async (data) => {
    try {
      const response = await BTPAuth.fetchData(
        apiUrl.registerUrl,
        "post",
        {},
        data,
      );
      return response;
    } catch (err) {
      errorHandling.handleErrors(err, err.response.status);
    }
  },

  deCodeUtm: async (data) => {
    try {
      const response = await BTPAuth.fetchData(
        apiUrl.utmDecodeUrl,
        "post",
        {},
        data,
      );
      return response.data;
    } catch (err) {
      errorHandling.handleErrors(err, err.response.status);
    }
  },

  updateInternalUser: async (data) => {
    //
    try {
      const response = await BTPAuth.fetchData(
        apiUrl.updateInternalUser,
        "patch",
        {},
        data,
      );
      return response;
    } catch (err) {
      errorHandling.handleErrors(err, err.response.status);
    }
  },

  deleteInternalUser: async (data) => {
    try {
      const response = await BTPAuth.fetchData(
        apiUrl.deleteInternalUserUrl,
        "delete",
        {},
        data,
      );
      return response;
    } catch (err) {
      errorHandling.handleErrors(err, err.response.status);
    }
  },
};

export default Profile;
