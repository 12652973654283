import { Route, Redirect } from "react-router-dom";
import React, { Suspense, lazy, Fragment } from "react";
import Cookies from "js-cookie";
import Spinner from "../../services/Spinner";
import PrivateRoute from "../../services/Authentication/PrivateRoute";
import AppHeader from "../AppHeader";
import AppSidebar from "../AppSidebar";
import AppFooter from "../AppFooter";

const Users = lazy(() => import("../../PortalPages/Users"));
const Dashboards = lazy(() => import("../../PortalPages/Dashboards"));
const BrandLabs = lazy(() => import("../../PortalPages/Brandlabs"));
const Products = lazy(() => import("../../PortalPages/Products"));
const Services = lazy(() => import("../../PortalPages/Services"));
const Feed = lazy(() => import("../../PortalPages/Feed/Feed"));
const Groups = lazy(() => import("../../PortalPages/Communities"));
const Email = lazy(() => import("../../PortalPages/Reports"));
const Ads = lazy(() => import("../../PortalPages/Ads"));
const Tracking = lazy(() => import("../../PortalPages/Tracking"));
const BuzzFeed = lazy(() => import("../../PortalPages/BuzzFeed"));
const Config = lazy(() => import("../../PortalPages/Config"));
const Videos = lazy(() => import("../../PortalPages/Videos"));
const Campaigns = lazy(() => import("../../PortalPages/Brandlabs/Campaigns"));
const Content = lazy(() => import("../../PortalPages/Content"));
const Stories = lazy(() => import("../../PortalPages/Stories"));
const ToDoList = lazy(() => import("../../PortalPages/ToDo"));
const Tools = lazy(() => import("../../PortalPages/Tools"));
const AppSetting = lazy(() => import("../../PortalPages/AppSetting"));
const Feedback = lazy(() => import("../../PortalPages/Feedback"));

function AppMain() {
  const accessToken = Cookies.get("auth_token");
  return (
    <>
      <AppHeader />
      <div className="app-main">
        <AppSidebar />
        <div className="app-main__outer">
          <div className="app-main__inner">
            <Suspense
              fallback={
                <div className="loader-container">
                  <div className="loader-container-inner">
                    <Spinner />
                  </div>
                </div>
              }
            >
              <PrivateRoute path="/user-management" component={Users} />
              <PrivateRoute path="/product-management" component={Products} />
              <PrivateRoute path="/service-management" component={Services} />
              <PrivateRoute path="/email" component={Email} />
              <PrivateRoute path="/reports" component={Email} />
              <PrivateRoute path="/feed" component={Feed} />
              <PrivateRoute path="/dashboards" component={Dashboards} />
              <PrivateRoute path="/communities" component={Groups} />
              <PrivateRoute path="/brandlabs" component={BrandLabs} />
              <PrivateRoute path="/campaigns-list" component={Campaigns} />
              <PrivateRoute path="/sponsored-posts" component={BrandLabs} />
              <PrivateRoute path="/ads" component={Ads} />
              <PrivateRoute path="/tracking" component={Tracking} />
              <PrivateRoute path="/buzz-feed" component={BuzzFeed} />
              <PrivateRoute path="/config" component={Config} />
              <PrivateRoute path="/videos" component={Videos} />
              <PrivateRoute path="/content" component={Content} />
              <PrivateRoute path="/stories" component={Stories} />
              <PrivateRoute path="/to-do-list" component={ToDoList} />
              <PrivateRoute path="/tools" component={Tools} />
              <PrivateRoute path="/app-setting" component={AppSetting} />
              <PrivateRoute path="/feedback" component={Feedback} />
            </Suspense>
          </div>
        </div>
      </div>
      <AppFooter />
      <Route
        exact
        path="/"
        render={() =>
          accessToken ? <Redirect to="/dashboards" /> : <Redirect to="/login" />
        }
      />
    </>
  );
}

export default AppMain;
