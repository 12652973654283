import React from "react";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import Login from "../services/Login";
import { useHistory } from "react-router-dom";
import Cookies from "js-cookie";
import { toast } from "react-toastify";

const GoogleSignInBtn = () => {
  const navigate = useHistory();

  // eslint-disable-next-line no-empty-function
  const handleGoogleLoginError = () => {};

  const handleGoogleLoginSuccess = async (googleToken) => {
    try {
      const response = await Login.loginSSO({
        googleTokenId: googleToken,
      });
      if (response.status === 200) {
        const path = Cookies.get("path");
        if (path !== "") {
          navigate.push(path);
        } else toast.error("You're not authorized!");
      }
    } catch (error) {
      console.log("Google Login Failed");
    }
  };

  return (
    <GoogleOAuthProvider clientId="285015230290-8hf0spbdpceq31bjbgc80bpg3e97i1qd.apps.googleusercontent.com">
      <GoogleLogin
        onSuccess={(response) => handleGoogleLoginSuccess(response.credential)}
        onError={handleGoogleLoginError}
        className="login-by-btn white-btn"
        shape="rectangular"
        theme="outline"
        size="medium"
        text="continue_with"
        logo_alignment="center"
        width="224px"
        type="standard"
      />
    </GoogleOAuthProvider>
  );
};

export default GoogleSignInBtn;
